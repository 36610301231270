import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import LocationAvailabilityService, { LocationAvailabilityServiceS } from '../../location-availability/location-availability.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarLocationAvailabilityPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(LocationAvailabilityServiceS) private lAvailabilityService!: LocationAvailabilityService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarLocationAvailabilityPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarLocationAvailabilityPageOtelLogs.spanPrefix);
        this.lAvailabilityService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarLocationAvailabilityPageOtelLogs.spanPrefix);
                this.endSpan(CarLocationAvailabilityPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.lAvailabilityService.storeState.settings) });
            });
    }
}
